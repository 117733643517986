// SHARE
(() => {
  const supportsPopover = () => {
    // eslint-disable-next-line no-prototype-builtins
    return HTMLElement.prototype.hasOwnProperty('popover');
  };
  const popoverSupported = supportsPopover();

  // print button
  const printButton = document.querySelector(
    '.tc_share__print:not(.js-processed)',
  );

  if (printButton) {
    printButton.classList.add('js-processed');
    printButton.addEventListener('click', () => {
      window.print();
    });
  }

  // copy button
  const copyLinkButton = document.querySelector(
    '.tc_share__popover__main__copylink__action',
  );

  // share button
  document.addEventListener('click', (e) => {
    const shareButton = e.target.closest('.tc_share__button');

    // share button is clicked
    if (shareButton) {
      const title =
        shareButton.getAttribute('data-target-title') || document.title;
      const url =
        shareButton.getAttribute('data-target-url') || document.location.href;

      // input field with URL is selected when focused
      const copyLinkInput = document.querySelector('#copylink');

      if (copyLinkInput) {
        copyLinkInput.addEventListener('focus', () =>
          setTimeout(function () {
            copyLinkInput.setSelectionRange(0, 9999);
          }, 1),
        );
      }

      // use Web Share API (if supported) and suppress popover
      if (navigator.share) {
        navigator
          .share({
            title: title,
            url: url,
          })
          .catch(console.error);

        e.preventDefault();
      } else {
        // if 'Web share API' is not supported a 'popover' will open automatically
        // - and if native 'popover' isn't supported either, this will emulate it.
        if (!popoverSupported) {
          const id = shareButton.getAttribute('popovertarget');
          const popover = document.querySelector(
            `.tc_share__popover[id="${id}"]`,
          );
          document.body.appendChild(popover);
          popover.setAttribute('data-open', true);
          document.onkeydown = (evt) => {
            if (evt.key === 'Escape') {
              popover.removeAttribute('data-open');
            }
          };
        }
      }
    } else {
      let popoverOpen;

      // look for open popover
      if (!popoverSupported) {
        popoverOpen = document.querySelector('.tc_share__popover[data-open]');
      } else {
        popoverOpen = document.querySelector('.tc_share__popover:popover-open');
      }

      // handle click events in open popover
      if (popoverOpen) {
        // copy button
        const copyLinkButtonClicked = e.target.closest(
          '.tc_share__popover__main__copylink__action',
        );

        if (copyLinkButtonClicked) {
          const url = copyLinkButton.getAttribute('data-url');
          copyToClipboard(url);
        }

        // close popover
        if (!popoverSupported) {
          popoverOpen.removeAttribute('data-open');
        }
      }
    }
  });

  const copyToClipboard = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      copyLinkButton.classList.add('tc_button--green');
    } catch (err) {
      copyLinkButton.classList.add('tc_button--tv2red');
    }
  };
})();
